<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="650">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  notification topic
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="cancelForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Title" v-model="notification_topic.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.notification_topic.title.$error">This information is required</span>

                        </v-col>
                        <v-col cols="12">
                            <v-select outlined dense :items="academic_class" v-model="notification_topic.academic_class_id"
                                      item-text="title"
                                      item-value="id" label="Select class">
                                <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should render selected items -->
                                    {{ data.item.title }} - {{ data.item.grade_title }}- {{ data.item.program.title }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    {{ data.item.title }} - {{ data.item.grade_title }}- {{ data.item.program.title }}
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.notification_topic.academic_class_id.$error">This information is required</span>


                        </v-col>

                        <v-col cols="12">
                          Status
                            <v-switch v-model="notification_topic.is_active"  :label="notification_topic.is_active?'Active':'Inactive'"></v-switch>
                        </v-col>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="cancelForm" class="text-gray btn btn-standard  cancel-btn">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary ">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    const  academicClassService = new AcademicClassService();
    import NotificationTopicsService from "@/core/services/notification-topics/NotificationTopicsService";
    const notificationTopicsService = new NotificationTopicsService();
    export default {
        name: 'create-notification-topic',
        validations: {
            notification_topic: {
                title: {required},
                academic_class_id: {required},

            }
        },
        data() {
            return {
                academic_class: [],
                dialog: false,
                edit: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                notification_topic: {
                    title: null,
                    academic_class_id: null,
                    is_active: true
                },
            };
        },
        mounted() {

        },
        methods: {
            getPrograms() {
                this.$bus.emit('toggleLoader',);
                academicClassService.all().then(response => {
                    this.academic_class = response.data.data;
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                    this.$bus.emit('toggleLoader');
                });
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getNotificationTopic(id)
                }else{
                    this.resetForm();
                }
                this.dialog = true;
                this.getPrograms();
                // this.$refs["my-modal"].show();
            },
            cancelForm(){
                this.resetForm();
                this.hideModal()
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, getNotificationTopic(id) {
                notificationTopicsService.show(id).then(response => {
                    this.notification_topic = response.data.notification_topic
                })
            },

            generateFd() {
                // console.log(this.notification_topic)
                let fd = new FormData();
                for (let key in this.notification_topic) {
                    if (key == "is_active" && this.notification_topic["is_active"]) {
                        fd.append("is_active", this.notification_topic ? 1 : 0);
                    } else {
                        if (this.notification_topic[key] != null) {
                            fd.append(key, this.notification_topic[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {

                this.$v.notification_topic.$touch();
                if (this.$v.notification_topic.$error) {
                    setTimeout(() => {
                        this.$v.notification_topic.$reset();
                    }, 3000);
                } else {
                    let fd = this.notification_topic;
                    if (this.edit) {
                        this.updateNotification(fd);
                    } else {
                        this.createNotification(fd);
                    }
                }
            },
            updateNotification(fd) {
                this.isBusy = true;
                notificationTopicsService
                    .update(this.notification_topic.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.resetForm();
                        this.hideModal();
                        this.$emit("refresh_notification");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            createNotification(fd) {
                this.isBusy = true;
                notificationTopicsService
                    .store(fd)
                    .then((response) => {
                        // console.log(response)
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");
                        this.hideModal();
                        this.$emit("refresh_notification");
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.notification_topic.$reset();
                this.edit = false;
                this.notification_topic = {
                    title: null,
                    is_active: true
                };
            }
        }
    };
</script>
