<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage notification topics</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Notification
                  topics
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="addNotification" class="mt-4 btn btn-primary mr-1" dark>
                  <i class="fa fa-plus"></i>
                  Add notification topic
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    v-on:keyup.enter="getNotificationsTopics()"
                    outlined
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getNotificationsTopics()"
                    v-model="search.active"
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" class="text-right">
                  <v-btn
                    @click="getNotificationsTopics()"
                    class="btn btn-primary btn-search w-35"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Class</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="px-3 wrap-column">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-show="notification_topics.length > 0"
                    v-for="(notification, index) of notification_topics"
                    :key="notification.id"
                  >
                    <td scope="row" class="px-3 wrap-column">
                      <a href="#" @click="editNotification(notification.id)" class="mr-2">
                        {{ notification.title }}</a
                      >
                    </td>
                    <td class="px-3 wrap-column">
                      {{
                        notification.academic_class
                          ? notification.academic_class.title
                          : "N/A"
                      }}
                    </td>
                    <td class="px-3 pt-3 wrap-column">
                      <span
                        class="badge text-lg"
                        v-bind:class="{
                          'badge-success': notification.is_active,
                          'badge-danger': !notification.is_active,
                        }"
                        >{{ notification.is_active ? "Active" : "Inactive" }}</span
                      >
                    </td>
                    <td>
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                    <a href="#" class="navi-link" @click="detail(notification.id)">-->
                          <!--                                                            <span class="navi-icon">-->
                          <!--                                                              <i class="fas fa-eye"></i>-->
                          <!--                                                            </span>-->
                          <!--                                                      <span class="navi-text"> View </span>-->
                          <!--                                                    </a>-->
                          <!--                                                  </b-dropdown-text>-->
                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              href="#"
                              class="navi-link"
                              @click="editNotification(notification.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteNotification(notification.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="notification_topics.length == 0">
                    <td colspan="8" class="text-center">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
              </tbody>
              </table>
              <b-pagination
                v-if="notification_topics.length > 0"
                class="pull-right mt-7"
                @input="getNotificationsTopics"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
          <create-and-update
            ref="notification"
            @refresh_notification="getNotificationsTopics"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import NotificationTopicsService from "@/core/services/notification-topics/NotificationTopicsService";
const notificationTopicsService = new NotificationTopicsService();
export default {
  name: "notification",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      notification_topics: [],
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
        is_parent: "null",
      },
      loading: false,
    };
  },
  mounted() {
    this.getNotificationsTopics();
  },
  methods: {
    getNotificationsTopics() {
      this.loading = true;
      notificationTopicsService
        .paginate(this.search)
        .then((response) => {
          this.notification_topics = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    addNotification() {
      this.$refs["notification"].showModal();
    },
    editNotification(id) {
      this.$refs["notification"].showModal(id);
    },

    deleteNotification(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            notificationTopicsService
              .delete(id)
              .then((response) => {
                this.getNotificationsTopics();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
  },
};
</script>
<style scoped></style>
